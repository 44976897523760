<template>
    <v-container id="status">
        <v-container v-if="!apiError">
            <a name="status" />
            <h1 class="text-h2 my-8">System status</h1>

            <v-list v-if="loaded">
                <v-list-item v-for="www in systemStatus.web" :key="www.name">
                    <v-list-item-icon>
                        <v-icon color="green" v-if="www.up">mdi-checkbox-marked-circle</v-icon>
                        <v-icon color="red" v-if="!www.up">mdi-close-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ www.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-progress-circular v-else indeterminate color="primary" size="24" />

            <h3 class="text-h6 my-4">BGP Peering Servers</h3>

            <v-list v-if="loaded">
                <v-list-item v-for="node in systemStatus.nodes" :key="node.name">
                    <v-list-item-icon>
                        <span v-if="node.up && node.config_age < 600"><v-icon color="green">mdi-checkbox-marked-circle</v-icon></span>
                        <span v-if="node.up && node.config_age >= 600"><v-icon color="orange">mdi-alert-circle</v-icon></span>
                        <span v-if="!node.up"><v-icon color="red">mdi-close-circle</v-icon></span>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ node.name }}
                            <span v-if="node.up && node.config_age < 600"> - Everything is working normally</span>
                            <span v-if="node.up && node.config_age >= 600"> - BGP peering is operational but not configuration has not updated in the last 10 minutes</span>
                            <span v-if="!node.up"> - Peering server down</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-progress-circular v-else indeterminate color="primary" size="24" />
        </v-container>

        <v-container v-else>
            <h2 class="text-h5 my-4">API error - cannot fetch system status.</h2>

            It is very likely that only the API and web app are affected as BGP peering nodes are fully independent and
            do not use this API. Please come back later when this issue is fixed.
        </v-container>

        <v-container>
            <a name="maintenance" />
            <h2 class="text-h2 my-8">Planned Maintenance</h2>
            <v-expansion-panels>
                <v-expansion-panel disabled>
                    <v-expansion-panel-header>No maintenance is planned at the moment.</v-expansion-panel-header>
                    <v-expansion-panel-content>No details.</v-expansion-panel-content>
                </v-expansion-panel>

                <!-- <v-expansion-panel>
                    <v-expansion-panel-header>
                        02-02-2022 Upgrading the capacity of peering node in <strong>Location</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        On 02-02-2022 we will upgrade the capacity of the public peering node in <strong>Location</strong>. All BGP peering with this node will be interrupted for up to 30 minutes. We recommend customers to configure redundant peering for their peers to .
                    </v-expansion-panel-content>
                </v-expansion-panel> -->
            </v-expansion-panels>
        </v-container>

        <v-container>
            <a name="incidents" />
            <h2 class="text-h2 my-8">Incidents</h2>
            <v-expansion-panels>
                <v-expansion-panel disabled>
                    <v-expansion-panel-header>No incidents yet.</v-expansion-panel-header>
                    <v-expansion-panel-content>No details.</v-expansion-panel-content>
                </v-expansion-panel>

                <!-- <v-expansion-panel>
                    <v-expansion-panel-header>
                        02-02-2022 Something's broken - web application (ongoing)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        12:23 pm UTC+0<br />
                        Our systems have detected a problem with the availability of the web application. Our engineers are currently busy diagnosing and resolving the issue.
                        <br /><br />
                        We expect to resolve the issue within 2 hours. Please follow this Status page for updates.
                    </v-expansion-panel-content>
                </v-expansion-panel> -->
            </v-expansion-panels>
        </v-container>
    </v-container>
</template>

<script>
import { useConstants } from "@/const/const.js";
import { ref, reactive } from "@vue/composition-api";
import axios from "axios";

const constants = useConstants();

export default {
    name: "StatusView",
    components: {},

    setup() {
        const systemStatus = reactive({});
        const apiError = ref(false);
        const loaded = ref(false);

        axios
            .get("/api/v1/system_status")
            .then((response) => {
                Object.assign(systemStatus, response.data);
                loaded.value = true;
            })
            .catch(() => {
                apiError.value = true;
            });

        return {
            constants,
            systemStatus,
            apiError,
            loaded,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: "%s | Status",
            link: [
                {rel: "canonical", href: `https://${constants.web_url}/status`}
            ]
        };
    },
};
</script>
